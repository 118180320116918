import React from 'react';
import NavBar from './NavBar';

const Hero = ({ text, bg, bgPosition, quoteName, height, padTop }) => {
    const bgStyle = `relative flex bg-cover ${bg} ${bgPosition} ${height}`;
    const padding = `${padTop}`;

    return (
        <section className={bgStyle}>

            <div className="absolute inset-0 bg-primary opacity-60"></div>
            <div className="w-10/12 mx-auto flex items-center justify-center text-3xl lg:text-6xl leading-loose lg:leading-normal font-bold text-white">
                <div className={padding}>
                    <h1 className="z-5 relative font-bold merriweather-regular text-center">
                        {text}
                    </h1>
                    {quoteName ? <h1 className="text-right open-sans z-5 relative text-2xl lg:text-4xl">{quoteName}</h1> : null}
                </div>
            </div>
        </section>
    );
};

export default Hero;