import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import Quote from './Quote';

const Contact = () => {
    const quote = '“The most important thing in communication is hearing what isn’t said.” – Peter Drucker'
    return (
        <div className="flex flex-col min-h-screen justify-between">
            <NavBar textColour="secondary" />
            <section className='w-full container mx-auto pt-40 text-primary'>
                <h1 className='text-center text-6xl merriweather-regular leading-normal'>Contact</h1>
                <div className='w-5/6 lg:w-3/5 mx-auto'>
                    <img src={process.env.PUBLIC_URL + '/images/collage.jpeg'} className="w-full lg:w-5/6mx-auto" alt="Profile Image" />
                        <h3 className='lg:text-3xl text-2xl leading-loose merriweather-regular mt-10 mb-6 text-center w-full'>Debbie Wang<br/>Relationship Education & Counselling</h3>
                    <div className="w-full lg:w-3/4 mx-auto text-left">
                    
                        <p className='contact-text'>Currently I am offering virtual sessions via Zoom.  In-person sessions only upon request.  Please E-mail: <a className="underline " href="mailto:debbiewang.phd@gmail.com">debbiewang.phd@gmail.com</a>.  I look forward to working with you!</p>
                        {/* <p className='contact-text'>In-person sessions only upon request.</p>
                        <p className='contact-text'>Please E-mail: <a className="underline " href="mailto:debbiewang.phd@gmail.com">debbiewang.phd@gmail.com</a> </p>
                        <p className='contact-text'>I look forward to working with you!</p> */}
                    </div>
                    

                    <img src={process.env.PUBLIC_URL + '/images/cartoon.jpeg'} className="w-4/5 lg:w-1/3 my-12 mx-auto" alt="Profile Image" />
                </div>
            </section>
            {/* Add your contact form or other content here */}
            <Footer />
        </div>
    );
};

export default Contact;