import React from 'react';
import NavBar from './NavBar';
import Accordion from './Accordion';
import Testimonials from './Testimonials';
import Hero from './Hero';
import Footer from './Footer';

const Supervision = () => {
    const quote = '“Supervision is an opportunity to bring someone back to their own mind, to show them how good they can be.”';
    const quoteName = ' – Nancy Kline';
    const testimonials = [
        {   imageUrl: process.env.PUBLIC_URL + '/images/cow.jpeg', 
            heading: 'Vulnerability Takes Courage',
            text: '“I\'ve just left a session that made me feel very awful... very stuck. Couldn\'t keep focus. Feeling lost! … You make it safe for me to show up \'as is\', thank you."',
            alt: 'Cows in a green pasture behind a fence',
            client: 'EFT Therapist Candidate', 
            isImageLeft: true, 
        },
        {   imageUrl: process.env.PUBLIC_URL + '/images/backyard.jpeg', 
            heading: 'Learning By Doing The Work',
            text: '“I was amazed by your deep understanding of EFT. It seemed effortless for you to connect everything together. The fact that every single one of the couples wants to come back for more is a testament to your skill and deep, true compassion. Thank you so much for the chance to see you in action.”',
            alt: 'Trees with wooden log sculptures',
            client: 'Hold Me Tight Roving Therapist/EFT Therapist', 
            isImageLeft: false, 
        }
    ]
    return (
        <div className="mx-auto text-primary bg-tertiary">
            <NavBar textColour={'white'} />
            <Hero bg={'bg-evergreen'} bgPosition={'bg-top'} text={quote} quoteName={quoteName} height={'h-screen'} padTop={'pt-24'} />
            <section className="template-section">
                <div className="template-container">
                    <h1 className="title">EFT Supervision</h1>
                    <p className="mb-6 w-5/6 mx-auto">
                    I am a certified Emotionally Focused Therapist and an approved supervisor by the International Centre for Excellence in Emotionally Focused Therapy (<a className='underline' href="https://iceeft.com/" target="_blank" rel="noreferrer">ICEEFT</a>) in all three certification pathways (EFIT, EFCT, EFFT).
                    </p>
                    <p className="mb-6 w-5/6 mx-auto">
                    The supervisor-supervisee relational dynamic is based on shared goals, clear expectations, and mutual trust. I am passionate in assisting and empowering aspiring therapists in developing necessary skills in their chosen area(s) during the <a className='underline' href="https://iceeft.com/eft-certification/" target="_blank" rel="noreferrer">EFT Certification</a> process.
                    </p>
                </div>
            </section>
            <section className="w-full mx-auto">
                <div className="accordion-container">
                    <Accordion title="What I Offer">
                        <p className="mb-6">I offer virtual supervision via Zoom, individual (60 min) or dyadic (90 min). The supervision process is conducted in a structured and engaging manner.  Every effort will be made to protect the confidentiality of the client.  The primary focus for me is to facilitate and model the key elements of EFT to create a safe, secure, and stimulating environment for the learners.  When we experience safety first-hand and have the courage to be vulnerable, we will then be able to help others to do the same.</p>
                    </Accordion>
                    <Accordion title="What do I expect of you">
                        <ul className="supervisions_custom-bullet">
                            <li className="mb-6">
                                Show your work and committing to growth
                            </li>
                            <li className="mb-6">
                                Prepare for each supervision with your case conceptualization and learning goals
                            </li>
                            <li className="mb-6">
                                Support your peer(s) during dyadic or group supervision with your full presence and helpful inquiries and feedback
                            </li>
                            <li className="mb-6">
                                Check in and let me know how best to support you
                            </li>
                        </ul>
                    </Accordion>
                    <Accordion title="What you can expect of me">
                        <ul className="supervisions_custom-bullet">
                            <li className="mb-6">
                                Help you to get organized and set personal learning goals
                            </li>
                            <li className="mb-6">
                                Help you get the most out of each case presentation conducted by you or your peer
                            </li>
                            <li className="mb-6">
                                Help you to develop and fine tune specific EFT skills and interventions
                            </li>
                            <li className="mb-6">
                                Helping you to tune in and process self-of-the-therapist
                            </li>
                            <li className="mb-6">
                                Provide you with feedback and encouragement with your work and your blocks
                            </li>
                            <li className="mb-6">
                                Support you through the certification process
                            </li>
                        </ul>
                    </Accordion>
                </div>
            </section>
            <div className="template-container open-sans">

                <p className="mb-6 w-5/6 mx-auto">The process will give you a sense of belonging and synergy to become a confident and competent Emotionally Focused Therapist.</p>
            </div>
            <section className='w-full mx-auto bg-white text-primary pt-16'>
                {testimonials.map((testimonial, index) => (
                    <Testimonials key={index} {...testimonial} />      
                ))}
            </section>
            <Footer bg={'tertiary'} />

     
        </div>
    );
};

export default Supervision;