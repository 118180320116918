import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './components/About';
import Counselling from './components/Counselling';
import Contact from './components/Contact';
import Home from './components/Home';
import Workshop from './components/Workshops';
import Supervision from './components/Supervision';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const App = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/about" element={<About />} />
                <Route path="/counselling" element={<Counselling />} />
                <Route path="/workshops" element={<Workshop />} />
                <Route path="/supervision" element={<Supervision />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/" element={<Home />} />
            </Routes>
        </Router>
    );
};

export default App;