import React from 'react';

const Testimonials = ({ imageUrl, alt, heading, text, client, isImageLeft }) => {
  return (
    <div className={`flex flex-col ${isImageLeft ? 'lg:flex-row' : 'lg:flex-row-reverse'} items-center py-12 w-11/12 mx-auto`}>
      <div className="w-full lg:w-1/2 mx-auto mb-12">
        <img src={imageUrl} alt={alt} className="w-full lg:w-4/5 h-auto mx-auto" />
      </div>
      <div className="w-full lg:w-1/2 px-4 mx-auto">
        <h1 className="w-full lg:w-4/5 text-4xl leading-loose merriweather-regular mx-auto">{heading}</h1>
        <p className="w-full lg:w-4/5 text-2xl leading-loose merriweather-regular mx-auto">{text}</p>
        <p className="w-full lg:w-4/5 text-2xl leading-loose open-sans text-right mx-auto"> - {client}</p>
      </div>
    </div>
  );
};

export default Testimonials;