import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ to, children, className = '', ...props }) => {
  return (
    <Link
      to={to}
      className={`inline-block max-w-max bg-secondary font-serif text-white text-center py-4 px-8 ${className}`}
      {...props}
    >
      {children}
    </Link>
  );
};

export default Button;