import React from 'react';
import NavBar from './NavBar';
import Accordion from './Accordion';
import Testimonials from './Testimonials';
import Footer from './Footer';
import Hero
 from './Hero';
const Workshops = () => {
    const hmtText = 'Hold Me Tight is a relationship educational program and a book by Dr. Sue Johnson based on the new science of love and the wisdom of Emotionally Focused Couple Therapy (EFT).  Hold Me Tight breaks this approach down into seven transforming conversations that use the defining moments in love relationships to foster secure and lasting bonds. This program is being offered around the world.'

    const testimonials = [
        {   imageUrl: process.env.PUBLIC_URL + '/images/follow_heart.jpeg', 
            text: '“Taking the Hold Me Tight class taught us to stop and reflect. Usually there is something deeper happening under the surface level arguments. Stepping back to understand what is really happening, then expressing our feelings of hurt, anger, pain in a new way that is more effective than what we knew how to do. It definitely requires commitment and practice but worth learning about. Debbie is really amazing with her words of wisdom and knowledge about relationships and expresses compassion making it really easy to listen and learn.”',
            alt:"A stone with an artistic painting of a heart with the text that says Follow Your Heart painted on it.",
            client: 'Female client (Married 33 Years)', 
            isImageLeft: true, 
        }
    ]

    const quote1 = '"Life is slippery.'
    const quote2 = 'Here, take my hand."'
    const quoteName = ' - H. Jackson Brown Jr.'
    

    return (
        <div className="mx-auto text-white bg-primary">
            <NavBar textColour={'white'} />
            <section className="template-section">
                <div className="lg:w-4/5 lg:mx-auto  mx-auto text-2xl leading-normal">

                    <h1 className="text-center text-6xl merriweather-regular  leading-normal">Hold Me Tight®</h1>
                    <h3 className="w-5/6 mx-auto text-2xl text-center">
                        Hold Me Tight® is a registered trademark to Sue Johnson
                    </h3>
                </div>
            </section>
            <div className="flex flex-col lg:flex-row items-center my-20 w-5/6 mx-auto">
                <div className="w-full lg:w-1/2 mx-auto mb-12">
                    <img src={process.env.PUBLIC_URL + '/images/hmt2.jpg'} alt="Photo of Hold Me Tight book by Sue Johnson" className="w-full lg:w-4/5 h-auto mx-auto" />
                </div>
                <div className="w-full lg:w-1/2 px-4 mx-auto">
                    <p className="w-full lg:w-4/5 text-2xl leading-loose open-sans mx-auto">{hmtText}</p>
                </div>
            </div>
            <section className="text-primary open-sans w-full mx-auto pt-28 pb-12 bg-tertiary text-primary;">
                <div className="lg:w-4/5 lg:mx-auto  mx-auto text-2xl leading-normal">
                    <p className="mb-6 w-5/6 mx-auto open-sans-bold">
                        Relationship HEART work with long lasting impact!
                    </p>
                    <p className="mb-12 w-5/6 mx-auto">
                        Trained by Dr. Sue Johnson and her colleagues, Debbie Wang will show you how to create a safe emotional bond that can last a lifetime.
                    </p>
                    <p className="mb-6 w-5/6 mx-auto open-sans-bold">
                        Research Studies on Hold Me Tight®
                    </p>
                    <p className="mb-12 w-5/6 mx-auto">
                        Hold Me Tight® is an intimate relationship practice-based program.  Research shows that Hold Me Tight®, 16 hours of focused relationship is equivalent to 4 months of therapy.  It helps 75% of couples move from a place of disconnection or loneliness to one of connection and closeness. Better yet, 90% of these couples kept their connection over time! International publications show positive results in more than 20 studies with couples therapy focused on emotions. 
                    </p>
                    <p className="mb-6 w-5/6 mx-auto open-sans-bold">
                        Who should attend:
                    </p>
                    <p className="mb-6 w-5/6 mx-auto">
                        If you're just starting your relationship and want to know how to do it right ...
                    </p>
                    <p className="mb-6 w-5/6 mx-auto">
                        If you've been together for a while and just need a tune-up ...
                    </p>
                    <p className="mb-6 w-5/6 mx-auto">
                        If you've lost that special connection and want to get it back ...
                    </p>
                    <p className="mb-6 w-5/6 mx-auto">
                        If you've tried marriage counselling before without success ...
                    </p>
                    <p className="mb-12 w-5/6 mx-auto">
                        Even if you are thinking about getting divorced ...consider joining the program.
                    </p>
                    <p className="mb-12 w-5/6 mx-auto">
                        This program works well as a supplement to ongoing couple therapy.  Couples with serious distress, such as domestic violence, substance abuse, or severe mental health challenges will need specialized interventions and support. 
                    </p>
                    <p className="mb-6 w-5/6 mx-auto open-sans-bold">
                        During this workshop, you will:
                    </p>
                    <ul className="workshop_custom-bullet">
                        <li className="mb-6 w-5/6">
                            Learn about love and attachment
                        </li>
                        <li className="mb-6 w-5/6">
                            Begin to make sense of your own and your partner’s emotions and needs
                        </li>
                        <li className="mb-6 w-5/6">
                            Understand how love goes wrong
                        </li>
                        <li className="mb-6 w-5/6">
                            Identify stuck points in your relationship
                        </li>
                        <li className="mb-6 w-5/6">
                            Learn how to talk about hurts without getting stuck
                        </li>
                        <li className="mb-6 w-5/6">
                            Learn how to apologize and forgive
                        </li>
                        <li className="mb-12 w-5/6">
                            Discover ways to keep love alive in your relationship
                        </li>
                    </ul>


                </div>

            </section>
            <h1 className="text-center text-5xl merriweather-regular pt-12 leading-normal">Program Formats</h1>
            <div className="accordion-container pt-12">
                <Accordion title="Hold Me Tight® 2-Day Weekend Workshop">
                    <p className="mb-6">The Workshop is taught by Debbie Wang, MSW, PhD specialized and trained in this technique along with 2-3 roving therapists to help couples during the private conversations. This workshop will be either online through Zoom or in-person at chosen location.  It consists of 7 conversations to improve the bond in the couple. Each conversation will have two stages: a psycho-educational content about love and a practical one guiding the couple to conversations. The key concepts of each module will be taught altogether. Meanwhile, breakout rooms will be used for couples' conversations to ensure comfort and intimacy. The couple will work PRIVATELY and in a safe space to develop the conversation..</p>
                    <p className="mb-6">
                    There will be NO asking for sharing experiences if they do not want to. That is, couples will not share any of their personal challenges and/or relational struggles with other couples.
                    </p>
                    <p className="mb-6 open-sans-bold">
                    Cost: $650 CAD per couple.  Early Bird Rate: $600 CAD by (date to be determined).
                    </p>
                    <a className='underline open-sans-bold' href="" target="_blank" rel="noreferrer">To Be Determined</a>
                </Accordion>
                <Accordion title="Hold Me Tight® Virtual Cafe">
                    <p className="mb-6">The same curriculum as the 2-day weekend workshop with the added features of between-session homework and facilitator follow-up.  For couples who cannot spare an entire weekend or wish to pace their learning accordingly, this is a great alternative to experience this award winning relationship enhancement program.  It can also serve as a refresher for those who have taken the Hold Me Tight workshop and want to solidify the learning and deepen their practice.  An 8-module online companion course is included in this series.</p>
                    <p className="mb-2">
                    First-time Hold Me Tight participants need to register for the entire series.
                    </p>
                    <p className="mb-8 open-sans-bold">
                    Cost: $850 CAD per couple.  Early Bird Rate: $800 CAD by TBD
                    </p>
                    <p className="mb-2">
                    Returning participants can pick and choose one or more sessions based on your learning needs for your relationship.
                    </p>
                    <p className="mb-8 open-sans-bold">
                    Cost: $75 CAD per couple for each Café session along with the online module.
                    </p>
                    <a className='underline open-sans-bold' href="" target="_blank" rel="noreferrer">To Be Determined</a>
                </Accordion>
                <Accordion title="Hold Me Tight® Online Companion Course">
                    <p className="mb-6">
                        The companion course designed by Debbie Wang via an online platform can be purchased here. It is a valuable supplement to ongoing couples therapy with Debbie or other EFT trained therapists.  This online course serves as an between-session reflective learning (with video content, key concepts, reflective questions, and additional resources) and experiential practice (doing each of the Hold Me Tight 7 Conversations with your partner at your own pace).  Any noteworthy insights and fet experiences will be debriefed during sessions with your therapist.</p>
                    <p className="mb-6 open-sans-bold">
                        Cost: $250 CAD per couple.
                    </p>
                    <a className='underline open-sans-bold' href="" target="_blank" rel="noreferrer">To Be Determined</a>
                </Accordion>
            </div>
            <section className='w-full mx-auto bg-white text-primary pt-16'>
                {testimonials.map((testimonial, index) => (
                    <Testimonials key={index} {...testimonial} />      
                ))}
            </section>
            <section className="relative pb-48 lg:pt-60 pt-28 bg-cover bg-two-plants bg-center">
                <div className="absolute inset-0 bg-primary opacity-60"></div>
                <div className="w-10/12 mx-auto flex items-center justify-center text-3xl lg:text-6xl leading-loose lg:leading-normal font-bold text-white">
                    <div className="">
                        <h1 className="z-5 relative font-bold merriweather-regular text-center">
                            {quote1}
                            <br />
                            {quote2}
                        </h1>
                        {quoteName ? <h1 className="text-right open-sans z-5 text-2xl lg:text-4xl relative">{quoteName}</h1> : null}
                    </div>
                </div>
            </section>
            <Footer textColour={'white'} bg={'primary'} />
        </div>
    );
};

export default Workshops;