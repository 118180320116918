import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => setIsOpen(!isOpen);

    return (
        <div className="accordion-section text-2xl leading-normal flex flex-col w-full lg:w-4/6 mx-auto pb-6">
            <div className="accordion-header flex items-center flex-row border-b-2 pb-2 border-current cursor-pointer" onClick={toggleOpen}>
                {isOpen ? <FaMinus /> : <FaPlus />}
                <span className="ml-4 merriweather-regular text-2xl font-bold">{title}</span>
            </div>
            {isOpen && <div className="accordion-content open-sans my-6">{children}</div>}
        </div>
    );
};

export default Accordion;