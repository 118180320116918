import React from 'react';
import Navbar from './NavBar';
import Footer from './Footer';
import Hero from './Hero';

const About = () => {
    const quote = '"To live. To love. To learn. To leave a legacy."'
    return (
        <div className='bg-primary'>
            <Navbar textColour='white'/>

            <section className='w-full mx-auto text-white relative pt-40'>
                {/* <h2 className="title">Credentials</h2> */}
                <div className="lg:w-4/5 lg:mx-auto  mx-auto pb-24">
                    <h1 className='title'>About Me</h1>
                    <img src={process.env.PUBLIC_URL + '/images/profile.jpeg'} className="mx-auto mb-16 w-5/6 lg:w-1/3" alt="A photo of Debbie Wang smiling at a conference." />
                    {/* <ul className="custom-bullet">
                        <li className="pl-4 text-2xl">Psychotherapist</li>
                        <li className="pl-4 text-2xl">Lecturer</li>
                        <li className="pl-4 text-2xl">Workshop Presenter</li>
                    </ul> */}
                    <p className="text-2xl leading-normal mb-6 w-5/6 mx-auto open-sans">
                        As a life-long learner of human experiences, I value relationships and believe in their power to change lives and transform society.  Working in the helping profession for over 25 years, I never cease to be amazed by the innate beauty, inner strength, and personal resilience I see in the people I serve.
                    </p>
                    <p className="text-2xl leading-normal mb-6 w-5/6 mx-auto open-sans">
                        By following new research findings and continuing to learn cutting edge approaches, I assist my clients in reaching their potential and build strong relationships to overcome life’s many challenges.
                    </p>
                </div>
                <div className="w-full flex flex-col lg:flex-row lg:mx-auto items-center lg:items-start justify-center lg:justify-between bg-white text-primary py-24 open-sans">
                    <div className="lg:w-4/5 flex flex-col items-center lg:items-start lg:flex-row mx-auto">

                        <div className="w-5/6 lg:w-2/5 mb-6 flex flex-col lg:flex-none">
                            <h3 className='merriweather-regular text-4xl mb-6'>Academic Profile</h3>
                            <a className='underline about-listings' href="https://uwaterloo.ca/social-development-studies/people-profiles/debbie-wang" target="_blank" rel="noreferrer">Renison University College</a>
                            <a className='underline about-listings' href="https://luther.wlu.ca/faculty/adjunct/debbie-wang/index.html" target="_blank" rel="noreferrer">Martin Luther University College</a>
                            <a className='underline about-listings' href="http://www.linkedin.com/in/debbiewang" target="_blank" rel="noreferrer">LinkedIn</a>
                            
                        </div>
                        <div className="w-5/6 lg:w-3/5 ">
                            <h3 className='merriweather-regular text-4xl mb-6'>Designations</h3>
                            <p className='about-listings'>Registered Social Worker (RSW) with Ontario College of Social Workers and Social Service Workers Registration #424941</p>
                            <p className='about-listings'>Certified therapist and supervisor with International Centre for Excellence in Emotionally Focused Therapy (<a className='underline about-listings' href="https://iceeft.com/" target="_blank" rel="noreferrer">ICEEFT</a>)</p>
                            <p className='about-listings'>Adult Attachment Interview (<a className='underline about-listings' href="https://center-for-attachment.com/wp-content/uploads/2020/01/Clinical-Applications-of-AAI_2008_chapter.pdf" target="_blank" rel="noreferrer">AAI</a>) rater</p>
                            <p className='about-listings'>Certified <a className='underline about-listings' href="https://www.emdria.org/about-emdr-therapy/" target="_blank" rel="noreferrer">EMDR</a> practitioner</p>
                            <p className='about-listings'>Qualifying Positive Intelligence <a className='underline about-listings' href="https://www.positiveintelligence.com/science/" target="_blank" rel="noreferrer">PQ</a> Coach</p>
                            <p className='about-listings'>Distinguished Toastmaster (<a className='underline about-listings' href="https://www.toastmasters.org/education/distinguished-toastmaster" target="_blank" rel="noreferrer">DTM</a>) with Toastmasters International</p>
                            <h3 className='merriweather-regular text-4xl mt-14 mb-6'>Publication</h3>
                            <p className='about-listings'>Wang, D. & Stalker C. A.  (2016). <a className='underline italic about-listings' href="https://uwaterloo.ca/social-development-studies/sites/default/files/uploads/documents/wang-stalker_chapter-7.pdf" target="_blank" rel="noreferrer">Attachment Theory.  In N. Coady & P. Lehmann (Eds.) Theoretical Perspectives for Direct Social Work Practice: A Generalist-Eclectic Approach.</a> (3rd ed., pp. 159-184). New York: Springer.</p>
                        </div>
                    </div>
                </div>
            </section>
            <Hero bg={'bg-beachsteps'} bgPosition={'bg-top'} text={quote} height={'h-[500px]'} />
            {/* <section className="relative flex items-center bg-beachsteps bg-top bg-cover py-40 ">
                <div className="absolute inset-0 bg-primary opacity-60"></div>
                <div className="w-10/12 mx-auto">
                    <div className="z-8 text-white flex justify-center">
                        <h1 className="z-10 text-4xl lg:text-6xl font-bold merriweather-regular leading-normal lg:leading-normal">Diligent in following new research findings and continuing to learn cutting edge approaches, I assist my clients in reaching their potential, building strong relationships to overcome life’s many challenges.</h1>
                    </div>
                </div>
            </section> */}
            <Footer textColour={'white'} bg={'primary'} />
        </div>
    );
};

export default About;